import React from 'react';

const QC = () => {
  const { search, hash } = window.location;
  let qcThemeURL = '';
  let qcParams = new URLSearchParams(search + hash);
  if (qcParams.has('app_only')) {
    // QC theme URL should not have app_only param
    qcParams.delete('app_only');
  }
  if (!qcParams.has('qc_theme')) {
    qcParams.append('qc_theme', 'true');
  }
  const subdomain = window?.location?.hostname?.split('.')?.[0] ?? '';
  qcThemeURL = `https://${subdomain}.getclickpost.com/?${qcParams}`; // iframe URL where QC theme data will be shown

  return (
    <iframe
      src={qcThemeURL}
      width="100%"
      border="0"
      height={window.innerHeight}
      title="Quick Commerce Theme"
      style={{ border: 'none', borderWidth: '0px' }}
    />
  );
};

export default QC;
